import { Alert, Anchor, Button, Group, Stack, Switch, Text } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { IconAlertCircle } from '@tabler/icons'
import { AxiosError } from 'axios'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { getUrlString, Yup } from '@/lib/utils'
import { useAuth } from '@/providers/AuthProvider'

import * as api from './api'
import {
  ConfirmPasswordField,
  EmailField,
  FirstNameField,
  LastNameField,
  PasswordField
} from './Fields'

interface Props {
  channel?: string
}

export default function Signup({ channel }: Props) {
  // Hooks
  const router = useRouter()
  const { login } = useAuth() || {}

  // Schema validation
  const schema = Yup.object().shape({
    email: Yup.string().email().required(),
    firstName: Yup.string().min(2).required(),
    lastName: Yup.string().min(2).required(),
    password: Yup.string().min(8).required(),
    confirmPassword: Yup.string()
      .required()
      .test('confirmPassword', 'As senhas não conferem', function (value) {
        return this.parent.password === value
      })
  })

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: ''
    },
    validate: yupResolver(schema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
    clearInputErrorOnChange: false
  })

  // Constants
  const { callbackUrl, site } = router.query
  const siteSlug = getUrlString(site)
  const callbackUrlSlug = getUrlString(callbackUrl)

  // States
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [termos, setTermos] = useState(false)

  // Actions
  const handleChangeTermos = () => {
    setTermos(!termos)
  }

  const handleSubmit = async () => {
    setLoading(true)
    setError('')

    try {
      const { status } = await api.signup({
        site: siteSlug,
        ...(channel ? { channel } : {}),
        userData: form.values
      })
      if (status === 201) {
        try {
          await login?.({ login: form.values.email, password: form.values.password })
          const callbackUrl = router.query?.callbackUrl
          const url = getUrlString(callbackUrl)
          router.push(callbackUrl ? url : '/')
        } catch (error) {
          setError('Ocorreu um erro ao fazer sua autenticação. Faça o login novamente.')
        }
      } else {
        setError('Ocorreu um erro ao fazer o cadastro. Tente novamente mais tarde.')
      }
    } catch (error) {
      const { response } = error as AxiosError
      if (response?.status === 400) {
        setError(Object.values(response.data || {}).join('<br />'))
      } else {
        setError('Ocorreu um erro ao fazer o cadastro. Tente novamente mais tarde.')
      }
    }
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  // Effects
  useEffect(() => {
    if (form.values.confirmPassword) form.validateField('confirmPassword')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.password])

  return (
    <>
      <Alert
        icon={<IconAlertCircle size={16} />}
        title="Ops! Algo deu errado."
        color="red"
        mb="md"
        withCloseButton
        hidden={!error}
        onClose={() => setError('')}>
        <Text dangerouslySetInnerHTML={{ __html: error }} m={0} />
      </Alert>

      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <FirstNameField inputProps={{ ...form.getInputProps('firstName') }} />
          <LastNameField inputProps={{ ...form.getInputProps('lastName') }} />
          <EmailField inputProps={{ ...form.getInputProps('email') }} />
          <PasswordField inputProps={{ ...form.getInputProps('password') }} />
          <ConfirmPasswordField inputProps={{ ...form.getInputProps('confirmPassword') }} />
          <Text size="sm">
            Ao acessar e utilizar esta plataforma, independente do dispositivo, você concorda com os{' '}
            <Anchor component={Link} href="/terms" target="_blank">
              Termos de Uso
            </Anchor>{' '}
            e você declara estar ciente e de acordo com a{' '}
            <Anchor component={Link} href="/privacy" target="_blank">
              Política de Privacidade
            </Anchor>
            .
          </Text>
          <Switch
            label="Estou de acordo com os termos de uso e políticas de privacidade da plataforma"
            onChange={() => handleChangeTermos()}
          />
        </Stack>
        <Group position="apart" mt="xl">
          <Anchor
            component={Link}
            size="sm"
            href={`/accounts/login${callbackUrlSlug ? `?callbackUrl=${callbackUrlSlug}` : ''}`}>
            Já possui conta?
          </Anchor>
          <Button type="submit" loading={loading} disabled={!termos}>
            Criar conta
          </Button>
        </Group>
      </form>
    </>
  )
}
