import {
  ActionIcon,
  Badge,
  createStyles,
  Loader,
  Paper,
  ScrollArea,
  Stack,
  Text
} from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { IconTruck } from '@tabler/icons'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import { KeyboardEvent, useState } from 'react'

import { MaskedTextInput } from '@/components/commons'
import * as DisplayData from '@/components/commons/DisplayData'
import { api, notificationHandler, Yup } from '@/lib/utils'
import { MaskedPostcode } from '@/lib/utils/masks'
import { Freight as FreightType } from '@/types'

const useStyles = createStyles(theme => ({
  scrollArea: {
    height: 'calc(100vh - 510px)',
    maxHeight: 'calc(100vh - 510px)',
    [theme.fn.smallerThan('sm')]: {
      height: 'auto',
      maxHeight: 'auto'
    }
  }
}))

export default function FreightSimulateForm({ slug }: { slug: string }) {
  // Hooks
  const router = useRouter()
  const { classes } = useStyles()

  // Constants
  const {
    site: siteSlug,
    channel: channelSlug,
    store: storeSlug,
    showcase: showcaseSlug
  } = router.query || {}
  const postalCodeRegex = /^(\d{5}-?\d{3})/

  // States
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [freightList, setFreightList] = useState<FreightType[] | null>(null)

  // Validation schema
  const schema = Yup.object().shape({
    postalCode: Yup.string().matches(postalCodeRegex, 'Digite um CEP válido').required()
  })

  // Mantine form
  const form = useForm({
    validate: yupResolver(schema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
    initialValues: { postalCode: '' }
  })

  const handleSubmit = async () => {
    if (!form.isValid()) return null

    setIsSubmitting(true)
    setFreightList([])

    try {
      const { data } = await api.post(
        `/${siteSlug}/channels/${channelSlug}/stores/${storeSlug}/showcases/${showcaseSlug}/products/${slug}/freight/`,
        { postalCode: form.values.postalCode }
      )
      if (data) setFreightList(data)
    } catch (error) {
      const { response } = error as AxiosError
      const message = response?.data as any
      notificationHandler({
        variant: 'error',
        message: message?.postalCode?.[0] || message?.detail
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Stack spacing="sm">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <MaskedTextInput
          MaskedComponent={MaskedPostcode}
          placeholder="Calcular frete"
          type="text"
          disabled={isSubmitting}
          {...form.getInputProps('postalCode')}
          onBlur={handleSubmit}
          onKeyDown={(event: KeyboardEvent<HTMLImageElement>) => {
            setFreightList(null)
            if (event.key === 'Enter') {
              event.preventDefault()
              handleSubmit()
            }
          }}
          rightSection={
            <ActionIcon color="custom" onClick={handleSubmit} disabled={!form.isValid()}>
              {isSubmitting ? <Loader size={24} /> : <IconTruck size={24} />}
            </ActionIcon>
          }
        />
      </form>

      {freightList && freightList.length > 0 && (
        <ScrollArea className={classes.scrollArea}>
          <Stack spacing="xs">
            {freightList?.map(option => (
              <Paper p="xs" key={option.carrierUid} withBorder>
                <Badge>
                  • <DisplayData.CurrencyField free value={option.cost} />
                </Badge>
                <Text size="sm" weight="bold">
                  {option.carrier}
                </Text>
                {!!option.customDeliveryMessage && (
                  <Text size="xs" italic color="dimmed">
                    {option.customDeliveryMessage}
                  </Text>
                )}
                {!!option.displayDeliveryDate && (
                  <Text size="sm">
                    <Text color="custom" component="span">
                      <strong>{option.timeCost}</strong>
                    </Text>{' '}
                    estimado em{' '}
                    <strong>
                      <DisplayData.DateField type="date" value={option.deliveryDate} />
                    </strong>
                  </Text>
                )}
              </Paper>
            ))}
          </Stack>
        </ScrollArea>
      )}
      {!isSubmitting && Array.isArray(freightList) && freightList.length === 0 ? (
        <Text size="sm">
          Nenhuma opção disponível para o CEP:{' '}
          <Text weight="bold" span>
            {form.values.postalCode}
          </Text>
        </Text>
      ) : null}
    </Stack>
  )
}
