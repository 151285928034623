import { Button, Stack, TextInput } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import { api, errorHandler, notificationHandler, Yup } from '@/lib/utils'

interface Props {
  onCallback?: () => void
  children?: React.ReactNode
}

export default function CodeConfirmation({ onCallback, children }: Props) {
  // Hooks
  const router = useRouter()
  const { site: siteSlug, 'powerup-token': powerupToken } = router.query || {}

  // States
  const [loading, setLoading] = useState<boolean>(false)

  // Form
  const schema = Yup.object().shape({
    code: Yup.string().required()
  })
  const form = useForm({
    validate: yupResolver(schema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
    initialValues: { code: '' }
  })

  // Actions
  const handleSubmit = async (newValues: { code: string }) => {
    setLoading(true)
    api
      .patch(`/${siteSlug}/accounts/one-time-token/${powerupToken}/`, {
        confirmation_code: newValues?.code
      })
      .then(() => {
        notificationHandler({
          variant: 'success',
          message: 'O código confirmado com sucesso!'
        })
      })
      .catch(error => {
        const errors = errorHandler(error?.response?.data)
        notificationHandler({
          variant: 'error',
          message: `${errors?.messages?.[0]}` || 'Erro ao confirmar o código de confirmação.'
        })
        setLoading(false)
      })
      .finally(() => onCallback?.())
  }

  const handleResend = async () => {
    setLoading(true)
    api
      .post(`/${siteSlug}/accounts/one-time-token/${powerupToken}/`)
      .then(() => {
        notificationHandler({
          variant: 'success',
          message: 'O código de confirmação foi enviado em seu e-mail!'
        })
      })
      .catch(error => {
        const errors = errorHandler(error?.response?.data)
        notificationHandler({
          variant: 'error',
          message: `${errors?.messages?.[0]}` || 'Erro ao enviar o código de confirmação.'
        })
      })
      .finally(() => {
        onCallback?.()
        setLoading(false)
      })
  }

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          {children}
          <TextInput
            placeholder="Código"
            type="text"
            disabled={loading}
            {...form.getInputProps('code')}
          />
          <Button type="submit" fullWidth loading={loading}>
            Confirmar código
          </Button>
          <Button variant="outline" fullWidth loading={loading} onClick={handleResend}>
            Reenviar código
          </Button>
        </Stack>
      </form>
    </>
  )
}
