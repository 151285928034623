import { Alert, Button, Grid, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconAlertCircle } from '@tabler/icons'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { api } from '@/lib/utils'

import { endpoints } from './api'

export default function FindChannelForm() {
  // Hooks
  const router = useRouter()
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)

  // Constants
  const { site } = router?.query || {}

  const form = useForm({
    initialValues: { channelSlug: '' },
    validate: { channelSlug: val => (val.length > 1 ? null : 'Digite um código válido') },
    validateInputOnChange: true
  })

  const isDisabled = !form.isTouched() || !form.isValid() || isRedirecting

  const handleSubmit = async () => {
    setError('')
    setIsLoading(true)
    try {
      const { status, data } = await api.get(
        endpoints.channel(site as string, form.values.channelSlug)
      )
      if (status === 200 && data?.slug) {
        setIsRedirecting(true)
        router.push(`/${data.slug}`)
      }
    } catch (error) {
      const { response } = error as AxiosError
      setError(
        response?.status === 404
          ? 'Não encontramos nenhum canal com este código.'
          : 'Houve um erro durante a busca, tente novamente.'
      )
    } finally {
      form.resetDirty()
      form.resetTouched()
      setIsLoading(false)
    }
  }

  return (
    <>
      <Alert
        icon={<IconAlertCircle size={16} />}
        title="Loja não encontrada."
        color="red"
        mb="md"
        withCloseButton
        onClose={() => setError('')}
        hidden={!error}>
        <Text>{error}</Text>
      </Alert>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid align="flex-end">
          <Grid.Col span={12} xs={9}>
            <TextInput
              withAsterisk
              label="Código"
              placeholder="Digite o código"
              inputWrapperOrder={['label', 'error', 'description', 'input']}
              errorProps={{ mb: 'xs' }}
              disabled={isLoading || isRedirecting}
              {...form.getInputProps('channelSlug')}
              onBlur={() => form.validateField('channelSlug')}
              onInput={() => setError('')}
            />
          </Grid.Col>
          <Grid.Col span={12} xs={3}>
            <Button size="md" type="submit" fullWidth disabled={isDisabled} loading={isLoading}>
              Acessar
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </>
  )
}
