import {
  Alert,
  Box,
  Button,
  createStyles,
  FileInput,
  Group,
  Paper,
  Radio,
  Stack,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
  UnstyledButton
} from '@mantine/core'
import { IconAlertTriangle, IconCategory, IconClipboard, IconPaperclip } from '@tabler/icons'
import { useRouter } from 'next/router'

import { useFetch } from '@/lib/hooks'
import { bytesToSize, getUrlString } from '@/lib/utils'

import { acceptTypes } from '../constants'

interface Props {
  inputProps: any
  onSelect?: (category: any) => void
}

const useStyles = createStyles(theme => ({
  option: {
    ':hover': {
      backgroundColor: theme.colors.gray[2]
    }
  }
}))

export function CategoriesListField({ inputProps, onSelect }: Props) {
  // Hooks
  const router = useRouter()
  const { classes } = useStyles()

  // Constants
  const { site } = router.query || {}
  const siteSlug = getUrlString(site)
  const { handleResetForm, selected, sellerSlug } = inputProps || {}

  // Fetch
  const { data: categoriesData, isValidating } = useFetch([
    siteSlug ? `/${siteSlug}/support/categories/` : null,
    { seller: sellerSlug }
  ])

  if (selected) {
    return (
      <Paper withBorder>
        <Text p="sm" size="sm">
          Categoria selecionada:
        </Text>
        <Box p="sm">
          <Group spacing="sm" position="apart">
            <Group spacing="sm">
              <Radio defaultChecked value="" mt={5} />
              <ThemeIcon>
                <IconCategory />
              </ThemeIcon>
              <Text>{selected.name}</Text>
            </Group>
            <Button variant="outline" size="xs" onClick={handleResetForm}>
              Alterar
            </Button>
          </Group>
        </Box>
      </Paper>
    )
  }

  return (
    <Paper withBorder>
      <Stack p="md" spacing="md">
        <Text size="sm">Selecione uma das categorias para abertura do atendimento:</Text>
        {!isValidating && categoriesData?.results && !categoriesData?.results?.length && (
          <Alert icon={<IconAlertTriangle />} color="orange">
            <Text>
              Não encontramos nenhuma categoria para a abertura do atendimento. Não será possível
              criar o atendimento no momento.
            </Text>
          </Alert>
        )}
      </Stack>
      <Stack spacing={0}>
        {categoriesData?.results?.map((category: any) => {
          const selectedCategoryUid = selected?.uid
          const { name, uid } = category || {}
          return (
            <UnstyledButton
              key={uid}
              p="sm"
              onClick={() => (selectedCategoryUid !== uid ? onSelect?.(category) : null)}
              className={classes.option}>
              <Group spacing="sm">
                <Radio value={uid} mt={3} defaultChecked={selectedCategoryUid === uid} />
                <ThemeIcon>
                  <IconCategory />
                </ThemeIcon>
                <Text>{name}</Text>
              </Group>
            </UnstyledButton>
          )
        })}
      </Stack>
    </Paper>
  )
}

export const SubjectField = ({ inputProps }: Props) => (
  <TextInput {...inputProps} name="title" type="text" placeholder="Assunto" />
)

export const DescriptionField = ({ inputProps }: Props) => (
  <Textarea
    {...inputProps}
    name="description"
    type="text"
    placeholder="Escreva com detalhes suas dúvidas e/ou problemas"
    multiple
    minRows={5}
    maxRows={5}
  />
)

export const AttachmentsField = ({ inputProps }: Props) => {
  const {
    disabled,
    hasAttachment,
    handleFileUpload,
    handleRemoveAttachment,
    handleClearAttachments,
    value
  } = inputProps || {}
  return (
    <>
      <Alert color="blue">
        <Text mb="sm">
          Selecione os arquivos que deseja anexar ao chamado. (Máximo de <em>5MB</em> por aquivo)
        </Text>
        <Button
          variant="outline"
          component="label"
          disabled={disabled}
          leftIcon={<IconPaperclip />}>
          <Text>{!hasAttachment ? 'Anexar arquivos' : 'Anexar mais arquivos'}</Text>
          <FileInput
            multiple
            accept={acceptTypes.join(',')}
            onChange={handleFileUpload}
            sx={{ display: 'none' }}
          />
        </Button>
      </Alert>

      {hasAttachment && (
        <Paper p="sm" withBorder>
          <Group mb="sm" position="apart">
            <Text>Arquivos anexados:</Text>
            <Button size="xs" onClick={handleClearAttachments}>
              Limpar
            </Button>
          </Group>
          <Stack spacing="xs">
            {value?.map((file: any) => (
              <Group position="apart" key={file?.name}>
                <Group>
                  <IconClipboard />
                  <Text>
                    {file?.name} {bytesToSize(file?.size)}
                  </Text>
                </Group>
                <Button size="xs" variant="outline" onClick={() => handleRemoveAttachment(file)}>
                  remover
                </Button>
              </Group>
            ))}
          </Stack>
        </Paper>
      )}
    </>
  )
}
