import { Button, Stack, Text } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useSWRConfig } from 'swr'

import { api, errorHandler, notificationHandler, Yup } from '@/lib/utils'
import { BankAccount as BankAccountType, Order as OrderType } from '@/types'

import * as CancellationFields from './Fields'

interface Props {
  order: OrderType
  handleCloseDrawer?: () => void
}

interface FormValues {
  cancellationReason: string
  bankAccount: BankAccountType | null
}

export default function Order({ order, handleCloseDrawer }: Props) {
  // Hooks
  const router = useRouter()
  const { mutate: mutateGlobal } = useSWRConfig()

  // Constants
  const { site: siteSlug } = router.query || {}
  const { cancellationRequiresBankAccount, orderNumber } = order || {}
  const initialValues: FormValues = {
    cancellationReason: '',
    bankAccount: null
  }

  // States
  const [isSubmitting, setIsSubmitting] = useState(false)

  const schema = Yup.object().shape({
    cancellationReason: Yup.string().required('Por qual motivo deseja cancelar?'),
    bankAccount: cancellationRequiresBankAccount
      ? Yup.object().required(
        'Para cancelar você precisa informar uma conta bancária para reembolso'
      )
      : Yup.object().nullable().notRequired()
  })

  const form = useForm({
    initialValues,
    validateInputOnChange: true,
    validate: yupResolver(schema)
  })

  // Actions
  const handleSubmit = async () => {
    setIsSubmitting(true)
    try {
      const data: { bankAccountUid?: string; cancellationReason: string } = {
        cancellationReason: form.values.cancellationReason
      }
      if (cancellationRequiresBankAccount && form?.values?.bankAccount?.uid) {
        data.bankAccountUid = form.values.bankAccount.uid
      }
      await api.post(`/${siteSlug}/orders/${orderNumber}/cancel/`, data).then(() => {
        mutateGlobal(`/${siteSlug}/orders/${orderNumber}/`)
        handleCloseDrawer?.()
        notificationHandler({
          variant: 'success',
          message: `Enviamos sua solicitação de cancelamento para o pedido ${orderNumber}. Aguarde enquanto processamos, você será notificado por e-mail`
        })
      })
    } catch (error: any) {
      const errors = errorHandler(error?.response?.data)
      notificationHandler({
        variant: 'error',
        message:
          `${errors?.fields?.[0]}: ${errors?.messages?.[0]}` || 'Erro ao solicitar cancelamento.'
      })
    }
    setIsSubmitting(false)
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <CancellationFields.CancellationReasonSelectField
          inputProps={{ ...form.getInputProps('cancellationReason') }}
        />
        <CancellationFields.CancellationReasonInputField
          inputProps={{ ...form.getInputProps('cancellationReason') }}
        />
        {cancellationRequiresBankAccount && (
          <Stack>
            <Text>
              O seu pedido já foi pago e precisa ser reembolsado, para isso precisamos que selecione
              uma das contas bancárias cadastradas para que possamos realizar o reembolso em até 5
              dias úteis.
            </Text>
            <CancellationFields.BankAccountField
              selected={form.values.bankAccount}
              onSelect={(newValue: BankAccountType) => form.setFieldValue('bankAccount', newValue)}
              inputProps={{}}
            />
          </Stack>
        )}
        <Button type="submit" disabled={!form.isDirty() || !form.isValid() || isSubmitting}>Solicitar</Button>
      </Stack>
    </form>
  )
}
