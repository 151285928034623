import { Alert, Box, Button, List, LoadingOverlay, Stack, Text, ThemeIcon } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { IconAlertTriangle, IconBrandWhatsapp, IconMail, IconPhone, IconX } from '@tabler/icons'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'
import { useSWRConfig } from 'swr'

import { SupportOrderAutocomplete, SupportSellerAutocomplete } from '@/containers/autocompletes'
import { useFetch } from '@/lib/hooks'
import { api, contactTypeUrl, getUrlString, notificationHandler, Yup } from '@/lib/utils'
import {
  Contact as ContactType,
  Order as OrderType,
  Partner as PartnerType,
  Seller as SellerType,
  Site as SiteType,
  Ticket as TicketType
} from '@/types'

import * as TicketsFormFields from './Fields'

interface Props {
  siteData: SiteType
  searchQuery?: any
  orderData?: OrderType
  handleClose?: () => void
}

interface FormValues {
  title: string
  description: string
  category?: { uid: string; name: string; orderRequired: boolean } | null
  order?: { orderNumber: string } | null
  attachments?: File[] | null
}

const CONTACT_ICONS = {
  phone: <IconPhone size={16} stroke={1.5} />,
  email: <IconMail size={16} stroke={1.5} />,
  whatsapp: <IconBrandWhatsapp size={16} stroke={1.5} />
}

export default function Ticket({ siteData, searchQuery, orderData, handleClose }: Props) {
  // Hooks
  const router = useRouter()
  const { mutate: mutateGlobal } = useSWRConfig()

  // Constants
  const { seller, site: siteSlug } = router.query || {}
  const sellerSlug = getUrlString(seller)

  // States
  const [errors, setErrors] = useState<any>(null)
  const [selectedSeller, setSelectedSeller] = useState<SellerType | null>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [ticketExists, setTicketExists] = useState<TicketType | null>(null)
  const [verifyingTicketExists, setVerifyingTicketExists] = useState<boolean>(false)

  // Fetch
  const { data, error } = useFetch([siteSlug ? `/${siteSlug}/` : null], { fallbackData: siteData })
  const { partners } = data?.supportConfiguration || {}

  const selectedPartnerSupportConfiguration = partners?.find(
    (partner: PartnerType) => partner?.seller?.uid === selectedSeller?.uid
  )
  const selectedSupportConfiguration = selectedPartnerSupportConfiguration
    ? {
        seller: selectedPartnerSupportConfiguration?.seller,
        ...selectedPartnerSupportConfiguration?.supportConfiguration
      }
    : { seller: data?.seller, ...data?.supportConfiguration }

  const selectedSellerContacts = selectedSupportConfiguration?.contacts

  const { allowMultipleTicketsPerOrder } = selectedSupportConfiguration || {}

  // Validation schema
  const initialValues: FormValues = {
    title: '',
    description: '',
    category: null,
    order: orderData ? { orderNumber: orderData.orderNumber } : null
  }

  const schema = Yup.object().shape({
    title: Yup.string().min(2).required().default(''),
    description: Yup.string().min(2).required().default(''),
    category: Yup.object({
      uid: Yup.string(),
      name: Yup.string(),
      orderRequired: Yup.boolean()
    }).required(),
    order: Yup.object()
      .nullable()
      .when('category', {
        is: (item: any) => item?.orderRequired,
        then: schema => schema.required()
      })
  })

  // Mantine form
  const form = useForm<FormValues>({
    validate: yupResolver(schema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
    clearInputErrorOnChange: false,
    initialValues
  })

  // Constants
  const hasAttachment = Array.isArray(form.values.attachments) && form.values.attachments.length > 0
  const hasContacts = Array.isArray(selectedSellerContacts) && selectedSellerContacts.length > 0
  const isLoading = (!data && !error) || verifyingTicketExists

  // Actions
  const handleSubmit = async () => {
    setErrors(null)
    setSubmitting(true)
    try {
      const { attachments, category, order, ...restValues } = form.values
      await api
        .post(`/${siteSlug}/support/tickets/`, {
          ...restValues,
          category: category?.uid,
          ...(order?.orderNumber ? { order: order.orderNumber } : {}),
          seller: selectedSeller?.slug || null
        })
        .then(response => {
          if (response?.status === 201 && attachments?.length) {
            Promise.all(
              attachments.map(async (file: any) => {
                const data = new FormData()
                data.append('file', file)
                try {
                  await api.post(
                    `/${siteSlug}/support/tickets/${response.data?.id}/attachments/`,
                    { file },
                    { headers: { 'Content-Type': 'multipart/form-data' } }
                  )
                } catch (error) {
                  notificationHandler({
                    variant: 'error',
                    message: `Erro no envio do arquivo ${file.name}`
                  })
                }
                return null
              })
            ).then((requests: any) => {
              const failedRequests = requests.filter(
                (request: any) => request?.status && ![200, 201].includes(request?.status)
              )
              if (failedRequests.length > 0) {
                setSubmitting(false)
                notificationHandler({
                  variant: 'error',
                  message: `Erro no envio de ${failedRequests.length} arquivo(s)`
                })
              }
            })
          }
          return response.data?.id
        })
        .then(response => {
          notificationHandler({
            variant: 'success',
            message: 'O seu atendimento foi criado com sucesso'
          })
          if (searchQuery) {
            const queryObject = new URLSearchParams(searchQuery)
            const newQuery = queryObject.toString()
            mutateGlobal(`/${siteSlug}/support/tickets/?${newQuery}`)
          } else {
            mutateGlobal(`/${siteSlug}/support/tickets/`)
          }
          setSubmitting(false)
          handleClose?.()
          form.reset()
          return response
        })
        .then(ticketId => router.push(`/help/tickets/${ticketId}`))
    } catch (error: any) {
      const errorData = error?.response?.data
      const errorKey = Object.keys(errorData)[0]
      setErrors(errorData)
      setSubmitting(false)
      const fields = new Map<string, string>([
        ['order', 'Pedido'],
        ['seller', 'Vendedor'],
        ['title', 'Assunto'],
        ['description', 'Descrição']
      ])
      notificationHandler({
        variant: 'error',
        message:
          `${fields.get(errorKey)}: ${errorData[errorKey]}` ||
          'Houve um erro durante o cadastro de pessoa jurídica'
      })
    }
  }

  const handleFileUpload = (payload: File[]) => {
    if (payload?.length > 10) return null

    const files = Array.isArray(form.values.attachments)
      ? [...form.values.attachments, ...payload]
      : [...payload]
    const filteredFiles = files
      .filter(file => file?.size < 5242880)
      .filter((file, index, array) => index === array.findIndex(fItem => fItem.name === file.name))
    return form.setFieldValue('attachments', filteredFiles)
  }

  const handleRemoveAttachment = (file: File) => {
    form.setFieldValue(
      'attachments',
      form.values?.attachments && form.values.attachments.length > 1
        ? form.values.attachments.filter((attached: File) => attached.name !== file.name)
        : []
    )
  }

  const verifyTicketOrderExists = useCallback(
    async (newOrder: any | null) => {
      if (newOrder) {
        setVerifyingTicketExists(true)
        const { data } = await api.get(
          `/${siteSlug}/support/tickets/?order__order_number=${newOrder?.orderNumber}`
        )
        const { results = [] } = data || {}
        const exists = results?.find((ticket: any) => ticket.status !== 'closed') || null
        setVerifyingTicketExists(false)
        return exists
      }
      return null
    },
    [siteSlug]
  )

  const handleSelectOrder = async (newOrder: any | null) => {
    setTicketExists(null)
    form.setFieldValue('order', newOrder)
    if (newOrder && !selectedSeller) setSelectedSeller(newOrder?.seller)

    const exists = await verifyTicketOrderExists(newOrder)
    setTicketExists(exists)
  }

  // Effects
  useEffect(() => {
    async function initialOrder() {
      if (orderData) {
        form.setFieldValue('order', orderData)
        setSelectedSeller(orderData.seller)

        const exists = await verifyTicketOrderExists(orderData)
        if (exists) {
          setTicketExists(exists)
        }
      }
    }
    initialOrder()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData, verifyTicketOrderExists])

  useEffect(() => {
    if (sellerSlug !== '' && !orderData) {
      const selectedPartner =
        data?.seller?.slug === sellerSlug
          ? data
          : partners?.find((partner: PartnerType) => partner?.seller?.slug === sellerSlug)
      if (selectedPartner) {
        setSelectedSeller(selectedPartner?.seller)
      }
    }
  }, [data, partners, orderData, sellerSlug])

  return (
    <Stack spacing="sm">
      <LoadingOverlay visible={isLoading} />
      {!orderData && (
        <SupportSellerAutocomplete
          selectedSeller={selectedSeller}
          required={true}
          submitting={submitting}
          onChange={(newSeller: SellerType | null) => {
            setSelectedSeller(newSeller)
            form.reset()
          }}
        />
      )}
      {selectedSeller && (
        <>
          <Stack spacing="sm" hidden={!!selectedSupportConfiguration?.isActive}>
            <Alert title="Atendimento desativado">
              <Text size="sm" weight="bold" italic>
                Este vendedor não possui atendimento pela plataforma.{' '}
                {hasContacts ? 'Entre em contato pelos canais abaixo:' : null}
              </Text>
              <List size="md" spacing="xs" mt="xs">
                {hasContacts ? (
                  selectedSellerContacts.map((contact: ContactType) => (
                    <List.Item
                      icon={
                        <ThemeIcon variant="outline" color="gray" size={24} radius="xl">
                          {CONTACT_ICONS[contact?.type]}
                        </ThemeIcon>
                      }
                      key={contact.contact}>
                      <Text
                        component="a"
                        href={
                          contact.url ? contact.url : contactTypeUrl(contact.type, contact.contact)
                        }
                        target="_blank">
                        {contact.contact}
                      </Text>
                    </List.Item>
                  ))
                ) : (
                  <List.Item
                    icon={
                      <ThemeIcon variant="light" color="orange" size={24} radius="xl">
                        <IconX size={16} />
                      </ThemeIcon>
                    }>
                    <Text size="sm">Este vendedor não possui nenhum contato cadastrado</Text>
                  </List.Item>
                )}
              </List>
            </Alert>
          </Stack>
          <Stack spacing="sm" hidden={!selectedSupportConfiguration?.isActive} pb={50}>
            {form.values.order && !!ticketExists && (
              <>
                <Alert icon={<IconAlertTriangle />} title="Chamado já existe">
                  <Text mb="xs">
                    Percebemos que você já possui um chamado aberto para este pedido. Caso possua
                    alguma outra dúvida em relação a este pedido, adicione uma nova mensagem ao seu
                    chamado:
                  </Text>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      component={Link}
                      href={`/help/tickets/${ticketExists?.id}`}
                      variant="outline">
                      Acompanhar Chamado
                    </Button>
                  </Box>
                </Alert>
              </>
            )}
            {(allowMultipleTicketsPerOrder || !ticketExists || !orderData) && (
              <>
                <TicketsFormFields.CategoriesListField
                  onSelect={(option: any) => {
                    form.setTouched({ category: true })
                    form.setFieldValue('category', option)
                  }}
                  inputProps={{
                    handleResetForm: () => {
                      form.reset()
                      setTicketExists(null)
                    },
                    sellerSlug: selectedSeller?.slug || '',
                    selected: form.values.category
                  }}
                />
                {!!form.values.category && (
                  <>
                    <SupportOrderAutocomplete
                      placeholder={
                        !form.values.category?.orderRequired
                          ? 'Buscar Pedido (opcional)'
                          : undefined
                      }
                      selectedOrder={orderData || form.values.order}
                      sellerSlug={selectedSeller ? selectedSeller.slug : ''}
                      changeable={!orderData}
                      required={form.values.category?.orderRequired}
                      onChange={(newOrder: any | null) => handleSelectOrder(newOrder)}
                    />
                    <Alert
                      color="orange"
                      hidden={!form.values.category?.orderRequired || !!form.values.order}>
                      <Text size="xs">
                        Para a categoria escolhida, é necessário selecionar um pedido.
                      </Text>
                    </Alert>
                    {(allowMultipleTicketsPerOrder || !ticketExists) && (
                      <Stack hidden={!!form.values.category?.orderRequired && !form.values.order}>
                        <TicketsFormFields.SubjectField
                          inputProps={{
                            required: true,
                            disabled: submitting,
                            error: errors?.title?.[0],
                            ...form.getInputProps('title')
                          }}
                        />
                        <TicketsFormFields.DescriptionField
                          inputProps={{
                            required: true,
                            disabled: submitting,
                            error: errors?.description?.[0],
                            ...form.getInputProps('description')
                          }}
                        />
                        <TicketsFormFields.AttachmentsField
                          inputProps={{
                            value: form.values?.attachments,
                            disabled: submitting,
                            hasAttachment,
                            handleFileUpload,
                            handleRemoveAttachment,
                            handleClearAttachments: () => {
                              form.setTouched({ attachments: false })
                              form.setFieldValue('attachments', null)
                            }
                          }}
                        />
                        <Button
                          color="primary"
                          fullWidth
                          loading={submitting}
                          onClick={handleSubmit}
                          disabled={
                            !form.isValid() || (!allowMultipleTicketsPerOrder && !!ticketExists)
                          }>
                          Enviar
                        </Button>
                      </Stack>
                    )}
                  </>
                )}
              </>
            )}
          </Stack>
        </>
      )}
    </Stack>
  )
}
