import {
  Avatar,
  Box,
  Button,
  Grid,
  Group,
  Stack,
  Text,
  Tooltip,
  useMantineTheme
} from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useMediaQuery } from '@mantine/hooks'
import cardValidator from 'card-validator'
import { useRouter } from 'next/router'
import { FormEvent, ReactNode, useState } from 'react'
import { useSWRConfig } from 'swr'

import { api, notificationHandler, Yup } from '@/lib/utils'
import {
  MaskedCreditCardNumberMultiple,
  MaskedCreditCardValidDate,
  MaskedTaxDocumentCPFCNPJ
} from '@/lib/utils/masks'
import { useRouterLoading } from '@/providers/RouterLoadingProvider'
import { Order as OrderType, PaymentMethodOption as PaymentMethodOptionType } from '@/types'

import * as CreditCardFormFields from './Fields'
import { cryptoToolkit, errorHandler } from './utils'

interface Props {
  children?: ReactNode
  values?: any | null
  order?: OrderType
  cardOptions: PaymentMethodOptionType[]
  onClose?: () => void
}

interface FormValues {
  number: string
  holder: string
  validThru: string
  cvc: string
  taxDocument: string
}

export default function Accounts({ children, order, cardOptions, onClose }: Props) {
  // Hooks
  const router = useRouter()
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)
  const { mutate: mutateGlobal } = useSWRConfig()
  const { isLoading: isLoadingGlobal } = useRouterLoading()

  // States
  const [isSubmitting, setIsSubmitting] = useState(false)

  // Constants
  const { creditCardUid, site: siteSlug } = router.query || {}
  const disabled = isSubmitting
  const errorMessages = {
    number: {
      required: 'Você precisa colocar o número que se encontra na frente de cartão',
      format: 'Digite um número de cartão válido'
    },
    holder: { required: 'Preencha com o nome exatamente como impresso no cartão' },
    validThru: {
      required: 'Informe a data de validade do cartão',
      format: 'Informe uma data válida de vencimento do seu cartão. Ex: MM/AA'
    },
    cvc: { required: 'Informe o código de segurança do cartão' },
    taxDocument: {
      required: 'Necessário informar o CPF/CNPJ do titular do cartão',
      format: 'Insira um documento válido para o CPF/CNPJ'
    }
  }

  // Validation schema
  const schema = Yup.object().shape({
    number: Yup.string()
      .test('valid-credit-card', errorMessages.number.format, value => {
        if (!value) return false
        const inputCard = cardValidator.number(value?.replace(/\s/g, ''))
        return inputCard?.isValid
      })
      .required(errorMessages.number.required),
    holder: Yup.string()
      .matches(
        /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ])+$/u,
        'Digite apenas letras'
      )
      .required(errorMessages.holder.required)
      .min(3)
      .max(65),
    validThru: Yup.string()
      .matches(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/, errorMessages.validThru.format)
      .test('valid-expiry-date', errorMessages.validThru.format, value => {
        if (!value) return false
        const [expMonth, expYear] = value.split('/')
        const today = new Date()
        const month = today.getMonth() + 1
        const year = today.getFullYear() % 100
        if (Number(expYear) < year) return false
        if (Number(expYear) === year && Number(expMonth) < month) return false
        return true
      })
      .required(errorMessages.validThru.required),
    cvc: Yup.string()
      .required(errorMessages.cvc.required)
      .min(3, errorMessages.cvc.required)
      .max(4, errorMessages.cvc.required),
    taxDocument: Yup.string()
      .matches(
        /^(\d{3}\.?\d{3}\.?\d{3}-?\d{2})$|^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$/,
        errorMessages.taxDocument.format
      )
      .required(errorMessages.taxDocument.required)
  })

  // Mantine form
  const form = useForm<FormValues>({
    validate: yupResolver(schema),
    validateInputOnChange: true,
    validateInputOnBlur: true,
    initialValues: {
      number: '',
      cvc: '',
      holder: '',
      validThru: '',
      taxDocument: ''
    }
  })

  // Actions
  const handleSubmit = async (newValues: FormValues) => {
    const [expMonth, expYear] = newValues.validThru.split('/')

    const cleanedValues = {
      holder: newValues.holder,
      expYear,
      expMonth,
      number: newValues.number.replace(/\s/g, ''),
      cvc: newValues.cvc
    }

    setIsSubmitting(true)

    const inputCard = cardValidator.number(cleanedValues.number)
    const option = cardOptions?.find(
      card => card?.paymentMethod?.name === inputCard?.card?.niceType
    )

    try {
      const encrypted = await cryptoToolkit(cleanedValues, option)
      const { pagseguro, iugu, cielo, creditCard } = encrypted || {}

      await api
        .post(`/${siteSlug}/credit-cards/`, {
          holder: newValues.holder,
          firstDigits: creditCard?.number.slice(0, 6),
          lastDigits: creditCard?.number.slice(-4),
          expMonth: creditCard?.expMonth,
          expYear: creditCard?.expYear,
          brand: option?.paymentMethod?.brand?.code,
          taxDocument: newValues.taxDocument,
          options: {
            ...(pagseguro?.hash ? { pagseguro } : {}),
            ...(iugu?.hash ? { iugu } : {}),
            ...(cielo?.hash ? { cielo } : {})
          }
        })
        .then(() => {
          if (creditCardUid) {
            mutateGlobal(`/${siteSlug}/credit-cards/${creditCardUid}/replace/`)
          }
        })

      notificationHandler({ variant: 'success', message: 'Cartão adicionado com sucesso' })

      if (order) {
        mutateGlobal(`/${siteSlug}/orders/${order.orderNumber}/change-payment-method/`)
      }

      onClose?.()
    } catch (error: any) {
      errorHandler(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Grid>
        <Grid.Col span={12} xs={children ? 7 : 12}>
          <Stack>
            <Box>
              <Text size="sm" color="dimmed">
                Você pode pagar com:
              </Text>
              <Group spacing="sm" mt="xs">
                {cardOptions?.map((option: any) => (
                  <Tooltip
                    key={option?.paymentMethod?.brand?.name}
                    label={option?.paymentMethod?.brand?.name}
                    withArrow>
                    <Avatar
                      size={isXs ? 'sm' : 'md'}
                      src={option?.paymentMethod?.brand?.logo}
                      alt={option?.paymentMethod?.brand?.name}
                    />
                  </Tooltip>
                ))}
              </Group>
            </Box>
            <CreditCardFormFields.NumberField
              inputProps={{
                ...form.getInputProps('number'),
                component: MaskedCreditCardNumberMultiple,
                disabled
              }}
              cardOptions={cardOptions}
              form={form}
              errorMessage={errorMessages.number.format}
            />
            <CreditCardFormFields.NameField
              inputProps={{
                ...form.getInputProps('holder'),
                disabled,
                onBlur: ({ currentTarget: { value } }: FormEvent<HTMLInputElement>) => {
                  form.setFieldValue('holder', value.replace(/[^\S\r\n]+/g, ' ').trim())
                }
              }}
            />
            <Group spacing="sm" noWrap align="flex-start" grow>
              <CreditCardFormFields.ValidThruField
                inputProps={{
                  ...form.getInputProps('validThru'),
                  component: MaskedCreditCardValidDate,
                  disabled
                }}
              />
              <CreditCardFormFields.CVVField
                inputProps={{ ...form.getInputProps('cvc'), disabled }}
              />
            </Group>
            <CreditCardFormFields.TaxDocumentField
              inputProps={{
                ...form.getInputProps('taxDocument'),
                component: MaskedTaxDocumentCPFCNPJ,
                disabled
              }}
            />
            <Group position="apart" spacing="md" my="lg">
              <Button
                size={isXs ? 'sm' : 'md'}
                fullWidth={!!isXs}
                variant="outline"
                disabled={isSubmitting || isLoadingGlobal}
                onClick={() => onClose?.()}>
                Voltar
              </Button>
              <Button
                type="submit"
                size={isXs ? 'sm' : 'md'}
                fullWidth={!!isXs}
                loading={isSubmitting}
                disabled={!form.isValid() || !form.isDirty() || disabled || isLoadingGlobal}>
                Continuar
              </Button>
            </Group>
          </Stack>
        </Grid.Col>
        <Grid.Col span={12} xs={5} hidden={!children}>
          {children}
        </Grid.Col>
      </Grid>
    </form>
  )
}
