import { Alert, Button, Group, Stack, Text, Title, UnstyledButton } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconAlertCircle, IconCircleCheck } from '@tabler/icons'
import { useState } from 'react'

import { useAuth } from '@/providers/AuthProvider'

import { EmailField, PasswordField } from './Fields'

export default function Login() {
  // Hooks
  const { forgotPassword, login } = useAuth() || {}

  // States
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [changePassword, setChangePassword] = useState(false)
  const form = useForm({
    initialValues: {
      email: '',
      password: ''
    },
    validate: {
      email: val => (/^\S+@\S+\.\S+$/.test(val) ? null : 'E-mail inválido'),
      password: val =>
        !changePassword && val.length < 6 ? 'Senha deve conter no mínimo 6 caracteres' : null
    }
  })

  // Actions
  const handleSubmit = async () => {
    setLoading(true)
    setMessage('')
    setError('')

    if (changePassword) {
      try {
        const response = await forgotPassword?.(form.values.email)
        const { status } = response || {}
        if (status === 204) {
          setMessage(
            'Enviamos um link para o e-mail cadastrado. Acesse o link para resetar a senha'
          )
        }
        form.reset()
      } catch (error: any) {
        const { response } = error
        setError(response?.data?.detail)
      }
    } else {
      try {
        await login?.({ login: form.values.email, password: form.values.password })
      } catch (error: any) {
        const { response } = error
        setError(response?.data?.detail)
      }
    }
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  return (
    <>
      <Alert
        icon={<IconCircleCheck size={16} />}
        title="Sucesso!"
        color="green"
        mb="md"
        hidden={!message}
        withCloseButton
        onClose={() => setMessage('')}>
        <Text m={0}>{message}</Text>
      </Alert>
      <Alert
        icon={<IconAlertCircle size={16} />}
        title="Ops! Algo deu errado."
        color="red"
        mb="md"
        hidden={!error}
        withCloseButton
        onClose={() => setError('')}>
        <Text dangerouslySetInnerHTML={{ __html: error }} m={0} />
      </Alert>

      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <Title size="sm">{changePassword ? 'Mudar Senha' : 'Login'}</Title>
          <EmailField inputProps={{ ...form.getInputProps('email') }} />

          {!changePassword && <PasswordField inputProps={{ ...form.getInputProps('password') }} />}
        </Stack>

        <Group position="apart" mt="xl">
          <UnstyledButton onClick={() => setChangePassword(!changePassword)}>
            <Text size="sm" color="blue">
              {changePassword ? 'Voltar' : 'Esqueceu sua senha?'}
            </Text>
          </UnstyledButton>
          <Button type="submit" loading={loading}>
            {changePassword ? 'Enviar' : 'Login'}
          </Button>
        </Group>
      </form>
    </>
  )
}
