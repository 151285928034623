import { Alert, Button, Group, Text, UnstyledButton } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconAlertCircle, IconCircleCheck, IconMail } from '@tabler/icons'
import { useState } from 'react'

import { useAuth } from '@/providers/AuthProvider'

import { PasswordField } from './Fields'

interface Props {
  email?: string
  onBack: () => void
}

export default function LoginModal({ email, onBack }: Props) {
  // Hooks
  const { login } = useAuth() || {}

  // States
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const form = useForm({
    initialValues: {
      email: email || '',
      password: ''
    },
    validate: {
      password: val => (val.length < 6 ? 'Senha deve conter no mínimo 6 caracteres' : null)
    }
  })

  // Actions
  const handleSubmit = async () => {
    setLoading(true)
    setMessage('')
    setError('')

    try {
      await login?.({ login: form.values.email, password: form.values.password })
    } catch (error: any) {
      const { response } = error
      setError(response?.data?.detail)
    }
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  return (
    <>
      <Alert
        icon={<IconCircleCheck size={16} />}
        title="Sucesso!"
        color="green"
        mb="md"
        hidden={!message}
        withCloseButton
        onClose={() => setMessage('')}>
        <Text m={0}>{message}</Text>
      </Alert>
      <Alert
        icon={<IconAlertCircle size={16} />}
        title="Ops! Algo deu errado."
        color="red"
        mb="md"
        hidden={!error}
        withCloseButton
        onClose={() => setError('')}>
        <Text dangerouslySetInnerHTML={{ __html: error }} m={0} />
      </Alert>

      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Text weight="bold" mb="xs" sx={{ alignItems: 'center', display: 'flex' }}>
          <IconMail /> {email}
        </Text>
        <PasswordField inputProps={{ ...form.getInputProps('password') }} />

        <Group position="apart" mt="xl" mb="xs">
          <UnstyledButton onClick={onBack}>
            <Text size="sm" color="blue">
              Entrar com outro e-mail
            </Text>
          </UnstyledButton>
          <Button type="submit" loading={loading}>
            Login
          </Button>
        </Group>
      </form>
    </>
  )
}
