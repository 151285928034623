import {
  ActionIcon,
  Alert,
  Badge,
  Box,
  Button,
  Group,
  Stack,
  Text,
  ThemeIcon,
  UnstyledButton
} from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { IconBookmark, IconPaperclip } from '@tabler/icons'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { api, getUrlString, notificationHandler, Yup } from '@/lib/utils'

import * as FollowUpFormFields from './Fields'

interface Props {
  ticketId: string
  mutate?: any
}

interface FormValues {
  description: string
  attachments?: File[] | null
  privacy?: number | null
  ticketStatus?: string | null
}

export default function FollowUpForm({ ticketId, mutate }: Props) {
  // Hooks
  const router = useRouter()

  // Constants
  const { site } = router.query || {}
  const siteSlug = getUrlString(site)
  const initialValues: FormValues = {
    description: '',
    attachments: null,
    privacy: 1,
    ticketStatus: 'open'
  }

  const schema = Yup.object().shape({
    description: Yup.string().required(
      'É necessário enviar uma mensagem para o acompanhamento do chamado'
    )
  })

  const form = useForm<FormValues>({
    initialValues,
    validate: yupResolver(schema)
  })

  const hasAttachment = Array.isArray(form.values.attachments) && form.values.attachments.length > 0

  // States
  const [submitting, setSubmitting] = useState<boolean>(false)

  // Actions
  const handleFileUpload = (payload: File[]) => {
    if (payload?.length > 10) return null

    const files = Array.isArray(form.values.attachments)
      ? [...form.values.attachments, ...payload]
      : [...payload]
    const filteredFiles = files
      .filter(file => file?.size < 5242880)
      .filter((file, index, array) => index === array.findIndex(fItem => fItem.name === file.name))

    return form.setFieldValue('attachments', filteredFiles.length > 0 ? filteredFiles : null)
  }

  const handleRemoveAttachment = (file: File) =>
    form.setFieldValue(
      'attachments',
      form.values?.attachments && form.values.attachments.length > 1
        ? form.values.attachments.filter((attached: File) => attached.name !== file.name)
        : null
    )

  const handleSubmit = async (newValues: any) => {
    setSubmitting(true)
    try {
      const { attachments, description } = newValues || {}
      await api
        .post(`/${siteSlug}/support/tickets/${ticketId}/follow-ups/`, {
          description
        })
        .then(response =>
          response?.data && attachments?.length
            ? Promise.all(
              attachments.map(async (file: File) => {
                const data = new FormData()
                data.append('file', file)
                data.append('follow_up', response.data?.uid)
                try {
                  await api.post(
                      `/${siteSlug}/support/tickets/${ticketId}/attachments/`,
                      { file },
                      { headers: { 'Content-Type': 'multipart/form-data' } }
                  )
                } catch (error) {
                  notificationHandler({
                    variant: 'error',
                    message: 'Erro no envio de arquivo(s)'
                  })
                }
                return null
              })
            ).then((requests: any) => {
              const failedRequests = requests.filter(
                (request: any) => request?.status && ![200, 201].includes(request?.status)
              )
              if (failedRequests.length) {
                setSubmitting(false)
                notificationHandler({
                  variant: 'error',
                  message: `Erro no envio de ${failedRequests.length} arquivo(s)`
                })
              }
            })
            : null
        )
        .then(() => {
          notificationHandler({ variant: 'success', message: 'Comentário enviado com sucesso' })
          form.reset()
          mutate?.()
        })
        .catch(() => {
          notificationHandler({
            variant: 'error',
            message:
              'Houve um erro ao enviar o seu comentário, tente novamente ou entre em contato com a nossa equipe'
          })
        })
    } catch (error: any) {
      setSubmitting(false)
      notificationHandler({
        variant: 'error',
        message:
          'Houve um erro ao enviar o seu comentário, tente novamente ou entre em contato com a nossa equipe'
      })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack spacing="sm">
        <Group px="sm" pt="sm" position="apart">
          <Group>
            <ThemeIcon color="blue" variant="light">
              <IconBookmark />
            </ThemeIcon>
            <Text>Acompanhamento</Text>
          </Group>
          <FollowUpFormFields.AttachmentsField
            inputProps={{
              hasAttachment,
              handleFileUpload
            }}
          />
        </Group>
        {hasAttachment && (
          <Box px="sm">
            <Alert color="blue" title="Arquivos anexados:">
              <Group position="apart">
                <Group spacing="xs">
                  {form.values.attachments?.map((attachment: File, index: number) => (
                    <Badge
                      key={`${index.toString()}`}
                      color="gray"
                      leftSection={
                        <ActionIcon size="xs" color="blue" radius="xl" variant="transparent">
                          <IconPaperclip />
                        </ActionIcon>
                      }
                      onClick={() => handleRemoveAttachment(attachment)}
                      sx={{ cursor: 'pointer', maxWidth: '100%' }}>
                      <Text color="dimmed">{attachment.name}</Text>
                    </Badge>
                  ))}
                </Group>
                <UnstyledButton onClick={() => form.setFieldValue('attachments', null)}>
                  Limpar
                </UnstyledButton>
              </Group>
            </Alert>
          </Box>
        )}
        <Box px="sm">
          <FollowUpFormFields.DescriptionField
            inputProps={{
              required: true,
              disabled: submitting,
              ...form.getInputProps('description')
            }}
          />
        </Box>
        <Group px="sm" pb="sm" position="right">
          <Button
            color="primary"
            loading={submitting}
            type="submit"
            disabled={!form.isValid() || !form.isDirty()}>
            Comentar
          </Button>
        </Group>
      </Stack>
    </form>
  )
}
