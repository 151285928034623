import {
  Button,
  createStyles,
  Group,
  Paper,
  Radio,
  Select,
  Stack,
  TextInput,
  UnstyledButton
} from '@mantine/core'
import { IconPlus } from '@tabler/icons'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { BankAccount } from '@/components/commons/DisplayData'
import { BankAccountDrawer } from '@/containers/navigation/BankAccountMenu'
import { useFetch } from '@/lib/hooks'
import { getUrlString } from '@/lib/utils'

const useStyles = createStyles(theme => ({
  selectBankAccountButton: {
    ':hover': {
      backgroundColor: theme.colors.gray[2]
    }
  }
}))

interface Props {
  inputProps: any
  selected?: any
  onSelect?: (bankAccount: any) => void
}

export function CancellationReasonSelectField({ inputProps }: Props) {
  const reasons = [
    { label: 'Não gostei do produto', value: 'Não gostei do produto' },
    { label: 'Achei uma oferta melhor', value: 'Achei uma oferta melhor' },
    { label: 'Comprei errado', value: 'Comprei errado' },
    { label: 'Me arrependi da compra', value: 'Me arrependi da compra' },
    { label: 'Não posso esperar a entrega', value: 'Não posso esperar a entrega' },
    { label: 'Não recebi ainda', value: 'Não recebi ainda' },
    { label: 'Outros', value: 'Outros' }
  ]

  return <Select data={reasons} placeholder="Escolha um motivo ou digite abaixo" {...inputProps} />
}

export const CancellationReasonInputField = ({ inputProps }: Props) => (
  <TextInput name="cancellationReason" type="text" {...inputProps} />
)

export function BankAccountField({ inputProps, selected, onSelect }: Props) {
  // Hooks
  const router = useRouter()
  const { classes } = useStyles()

  // Constants
  const { site } = router.query || {}
  const siteSlug = getUrlString(site)

  // States
  const [open, setOpen] = useState(false)

  // Fetch
  const { data } = useFetch([siteSlug ? `/${siteSlug}/bank-accounts/` : null])

  return (
    <>
      <Stack spacing="sm">
        {data?.map((bankAccount: any) => (
          <UnstyledButton key={bankAccount.uid} onClick={() => onSelect?.(bankAccount)}>
            <Paper withBorder>
              <Group noWrap position="apart" p="sm" className={classes.selectBankAccountButton}>
                <BankAccount
                  {...bankAccount}
                  bankCode={bankAccount.bank.code}
                  bankName={bankAccount.bank.name}
                />
                <Radio
                  value=""
                  checked={bankAccount.uid === selected?.uid}
                  onClick={() => onSelect?.(bankAccount)}
                  onChange={() => {}}
                />
              </Group>
            </Paper>
          </UnstyledButton>
        ))}
        <Button variant="outline" p="sm" rightIcon={<IconPlus />} onClick={() => setOpen(true)}>
          Cadastrar nova conta bancária
        </Button>
      </Stack>
      <BankAccountDrawer open={open} onClose={() => setOpen(false)} onSelect={onSelect} />
    </>
  )
}
