import { Button, FileInput, Textarea } from '@mantine/core'
import { IconPaperclip } from '@tabler/icons'

import { acceptTypes } from '../constants'

interface Props {
  inputProps: any
}

export const AttachmentsField = ({ inputProps }: Props) => {
  const { hasAttachment, handleFileUpload } = inputProps || {}
  return (
    <Button
      size="xs"
      variant="outline"
      component="label"
      leftIcon={<IconPaperclip />}>
      {hasAttachment ? 'Anexar mais' : 'Anexar'}
      <FileInput
        multiple
        accept={acceptTypes.join(',')}
        onChange={handleFileUpload}
        sx={{ display: 'none' }}
      />
    </Button>
  )
}

export const DescriptionField = ({ inputProps }: Props) => (
  <Textarea
    name="description"
    placeholder="Descreva com detalhes suas dúvidas ou problemas para o acompanhamento do chamado"
    minRows={5}
    maxRows={5}
    {...inputProps}
  />
)
