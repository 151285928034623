import {
  ActionIcon,
  Avatar,
  Divider,
  Drawer,
  Group,
  ScrollArea,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
  UnstyledButton,
  useMantineTheme
} from '@mantine/core'
import { useDebouncedState, useMediaQuery } from '@mantine/hooks'
import { IconSearch } from '@tabler/icons'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import { useFetch } from '@/lib/hooks'
import { getUrlString } from '@/lib/utils'
import { MaskedTaxDocumentCPFCNPJ } from '@/lib/utils/masks'
import { normalize } from '@/lib/utils/stringCompare'
import { Bank as BankType } from '@/types'

interface Props {
  inputProps: any
}

export function AccountTypeField({ inputProps }: Props) {
  // Constants
  const depositOptions = [
    { label: 'Conta-corrente', value: 'checking' },
    { label: 'Conta Poupança', value: 'saving' }
  ]
  return (
    <Select
      label="Tipo de conta bancária"
      data={depositOptions}
      name="accountType"
      placeholder="Tipo de conta bancária"
      {...inputProps}
    />
  )
}

export const HolderField = ({ inputProps }: Props) => (
  <TextInput
    label="Nome completo conforme cadastro"
    name="holder"
    type="text"
    placeholder="JOSÉ SILVA"
    {...inputProps}
  />
)

export const TaxDocumentField = ({ inputProps }: Props) => (
  <TextInput
    label="CPF/CNPJ"
    name="taxDocument"
    type="text"
    component={MaskedTaxDocumentCPFCNPJ}
    placeholder="000.000.000-00"
    {...inputProps}
  />
)

export function CommonBankSearchField({ inputProps }: Props) {
  // Hooks
  const router = useRouter()
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)

  // Constants
  const { defaultSearchValue = '', error, onSelect, selected, disabled = false } = inputProps
  const { site } = router.query || {}
  const siteSlug = getUrlString(site)

  // States
  const [open, setOpen] = useState<boolean>(false)
  const [search, setSearch] = useDebouncedState(defaultSearchValue || '', 1000)

  // Actions
  const filterBanks = (option: any) => {
    const { code, name } = option || {}
    const cleanSearch = normalize(search)
    const cleanName = normalize(name)
    return cleanName?.indexOf(cleanSearch) !== -1 || code?.indexOf(cleanSearch) !== -1
  }

  const handleSelect = (choice: BankType) => {
    onSelect(choice)
    setOpen(false)
  }

  // Fetch
  const { data: banks } = useFetch([siteSlug ? `/${siteSlug}/banks/` : null])
  const options = Array.isArray(banks) ? banks.filter(filterBanks) : []

  return (
    <>
      <TextInput
        value={(selected && selected.name) || ''}
        disabled={disabled}
        label="Banco"
        rightSection={
          <ActionIcon disabled={disabled} color="custom" onClick={() => !disabled && setOpen(true)}>
            <IconSearch />
          </ActionIcon>
        }
        error={error}
        onClick={() => !disabled && setOpen(true)}
        onChange={() => {}}
      />
      <Drawer
        opened={open}
        title={<Title order={3}>Buscar banco</Title>}
        padding="lg"
        size={isXs ? 'full' : 'xl'}
        position="right"
        onClose={() => setOpen(false)}>
        <TextInput
          name="search"
          mb="sm"
          defaultValue={search}
          onChange={event => setSearch(event.target.value)}
          label="Pesquisar"
          placeholder="Banco"
        />
        <ScrollArea style={{ height: 'calc(100vh - 160px)' }} offsetScrollbars>
          {options && (
            <Stack>
              {options?.length === 0 ? (
                <Text>Nenhuma banco encontrado {search}</Text>
              ) : (
                options?.map((option: any, index: number) => (
                  <React.Fragment key={option.uid}>
                    <UnstyledButton onClick={() => handleSelect(option)}>
                      <Group spacing="sm">
                        <Avatar>{option && option.code}</Avatar>
                        <Text>{option.name}</Text>
                      </Group>
                    </UnstyledButton>
                    {index < options.length - 1 && <Divider />}
                  </React.Fragment>
                ))
              )}
            </Stack>
          )}
        </ScrollArea>
      </Drawer>
    </>
  )
}

export const AgencyNumberField = ({ inputProps }: Props) => (
  <TextInput label="Agência" name="agencyNumber" type="text" placeholder="0000" {...inputProps} />
)

export const AgencyCheckNumberField = ({ inputProps }: Props) => (
  <TextInput label="Dígito" name="agencyCheckNumber" type="text" placeholder="0" {...inputProps} />
)

export const AccountNumberField = ({ inputProps }: Props) => (
  <TextInput
    label="Número da conta"
    name="accountNumber"
    type="text"
    placeholder="00000"
    {...inputProps}
  />
)

export const AccountCheckNumberField = ({ inputProps }: Props) => (
  <TextInput label="Dígito" name="accountCheckNumber" type="text" placeholder="0" {...inputProps} />
)
