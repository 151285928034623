import {
  Drawer,
  DrawerPosition,
  ScrollArea,
  Title,
  useMantineTheme
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import { BankAccountForm } from '@/containers/forms'
import { BankAccount as BankAccountType } from '@/types'

interface Props {
  open: boolean
  position?: DrawerPosition
  onClose: () => void
  onSelect?: (bankAccount: BankAccountType) => void
}

export default function BankAccountDrawer({
  open,
  position = 'right',
  onClose,
  onSelect
}: Props) {
  // Hooks
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)

  return (
    <Drawer
      opened={open}
      onClose={onClose}
      padding="lg"
      size={isXs ? 'full' : 'xl'}
      title={<Title order={3}>Conta bancária</Title>}
      position={position}>
      <ScrollArea style={{ height: 'calc(100vh - 100px)' }} offsetScrollbars>
        <BankAccountForm.Order handleCloseDrawer={onClose} onSelect={onSelect} />
      </ScrollArea>
    </Drawer>
  )
}
