import { ActionIcon, Badge, Box, Group, Input, Loader, useMantineTheme } from '@mantine/core'
import { IconPlus, IconTag, IconTrash } from '@tabler/icons'
import { useRouter } from 'next/router'
import React, { FormEvent, useState } from 'react'

import { useFetch } from '@/lib/hooks'
import { api, getUrlString, notificationHandler } from '@/lib/utils'
import { useAuth } from '@/providers/AuthProvider'

export default function VoucherForm() {
  // Hooks
  const router = useRouter()
  const theme = useMantineTheme()
  const { isAuthenticated } = useAuth() || {}

  // Constants
  const { site } = router.query || {}
  const siteSlug = getUrlString(site)

  // Fetch
  const {
    data: basketData,
    mutate,
    isValidating
  } = useFetch([siteSlug && isAuthenticated ? `/${siteSlug}/basket/` : null])

  // Constants
  const { vouchers } = basketData || {}

  // States
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingRemove, setLoadingRemove] = useState<boolean>(false)
  const [voucher, setVoucher] = useState<string>('')

  // Actions
  const handleChange = ({ currentTarget: { value } }: FormEvent<HTMLInputElement>) => {
    setVoucher(value)
  }

  const removeVoucher = async (voucher: string) => {
    setLoadingRemove(true)
    try {
      const { status } = await api.delete(`/${siteSlug}/basket/voucher/`, {
        data: { voucherCode: voucher }
      })
      if (status === 200) {
        mutate()
      }
    } catch (error: any) {
      const [errors] = error.response.data.voucherCode || error.response.data.nonFieldErrors
      notificationHandler({
        variant: 'error',
        title: 'Ops algo deu errado.',
        message: errors
      })
    }
    setLoadingRemove(false)
  }

  const addVoucher = async () => {
    if (!voucher) return null

    setLoading(true)
    try {
      const { status } = await api.post(`/${siteSlug}/basket/voucher/`, { voucherCode: voucher })
      if (status === 200) {
        mutate()
        setVoucher('')
        notificationHandler({
          variant: 'success',
          message: `Adicionamos o cupom ${voucher} ao seu carrinho!`
        })
      }
    } catch (error: any) {
      const [errors] = error.response.data.voucherCode || error.response.data.nonFieldErrors
      notificationHandler({
        variant: 'error',
        title: 'Ops algo deu errado.',
        message: errors
      })
    }
    setLoading(false)
  }

  return (
    <>
      <Input
        name="voucher"
        value={voucher}
        onChange={handleChange}
        placeholder="Cupom"
        rightSection={
          loading ? (
            <Loader size="xs" />
          ) : (
            <ActionIcon variant="subtle" size="md" color="custom" onClick={addVoucher}>
              <IconPlus size={18} />
            </ActionIcon>
          )
        }
        onKeyPress={(ev: React.KeyboardEvent) => {
          if (ev.key === 'Enter') {
            ev.preventDefault()
            addVoucher()
          }
        }}
        disabled={loading}
      />

      {Array.isArray(vouchers) && (
        <Group mt="sm" spacing="xs" hidden={vouchers.length === 0}>
          {vouchers.map(({ code }: { name: string; code: string }) => (
            <Badge
              key={code}
              variant="light"
              size="lg"
              leftSection={
                <Box mt={theme.spacing.sm / 2} mr={theme.spacing.xs / 3}>
                  <IconTag size={16} />
                </Box>
              }
              rightSection={
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="light"
                  disabled={isValidating}
                  onClick={() => (!loadingRemove ? removeVoucher(code) : null)}>
                  {loadingRemove ? <Loader size={14} /> : <IconTrash size={14} />}
                </ActionIcon>
              }
              sx={{ paddingLeft: 6, paddingRight: 3 }}>
              {code}
            </Badge>
          ))}
        </Group>
      )}
    </>
  )
}
