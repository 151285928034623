import { api } from '@/lib/utils'
import { Channel as ChannelType, Showcase as ShowcaseType } from '@/types'

interface StudentFormValues {
  picture?: any
  firstName?: string
  lastName?: string
  taxDocument?: string
  birthdate?: string | null
  channel?: ChannelType | null
  showcase?: ShowcaseType | null
}

export const endpoints = {
  signup: (site: string) => `/${site}/students/`,
  update: (site: string, studentUid: string) => `/${site}/students/${studentUid}/`
}

export const signup = ({ site, studentData }: { site: string; studentData: StudentFormValues }) => {
  const { picture, firstName, lastName, taxDocument, birthdate, channel, showcase } =
    studentData || {}

  const data = new FormData()
  if (picture) data.set('picture', picture)
  if (firstName) data.set('firstName', firstName)
  if (lastName) data.set('lastName', lastName)
  if (taxDocument) data.set('taxDocument', taxDocument?.replace(/\D/g, ''))
  if (birthdate) data.set('birthdate', birthdate)
  if (channel) data.set('channel', channel?.slug)
  if (showcase) data.set('showcase', showcase?.uid)

  const config = {
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    url: endpoints.signup(site),
    data
  }
  return api(config)
}

export const update = async ({
  site,
  studentUid,
  studentData
}: {
  site: string
  studentUid: string
  studentData: StudentFormValues
}) => {
  const { picture, firstName, lastName, taxDocument, birthdate, channel, showcase } =
    studentData || {}

  const data = new FormData()
  if ((!!picture && typeof picture !== 'string') || picture === null) {
    data.set('picture', picture || '')
  }
  if (firstName) data.set('firstName', firstName)
  if (lastName) data.set('lastName', lastName)
  if (taxDocument) data.set('taxDocument', taxDocument?.replace(/\D/g, ''))
  if (birthdate) data.set('birthdate', birthdate)
  if (channel) data.set('channel', channel?.slug)
  if (showcase) data.set('showcase', showcase?.uid)

  const config = {
    method: 'patch',
    headers: { 'Content-Type': 'multipart/form-data' },
    url: endpoints.update(site, studentUid),
    data
  }
  return api(config)
}
