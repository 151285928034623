import { Checkbox, Text, TextInput } from '@mantine/core'

interface Props {
  inputProps: any
  checked?: boolean
}

export const OfficialName = ({ inputProps }: Props) => (
  <TextInput label="Razão social" placeholder="Razão social" type="text" {...inputProps} />
)

export const FantasyName = ({ inputProps }: Props) => (
  <TextInput label="Nome fantasia" placeholder="Nome fantasia" type="text" {...inputProps} />
)

export const CompanyTaxDocument = ({ inputProps }: Props) => (
  <TextInput label="CNPJ" placeholder="Digite seu CNPJ" type="text" {...inputProps} />
)

export const CompanyStateTaxDocumentCheckbox = ({ inputProps, checked }: Props) => (
  <Checkbox
    label={<Text weight={checked ? 'bold' : 'normal'}>Possui inscrição estadual?</Text>}
    {...inputProps}
    sx={{ label: { '&:hover': { cursor: 'pointer' } } }}
  />
)

export const CompanyStateTaxDocument = ({ inputProps }: Props) => (
  <TextInput
    label="Inscrição estadual (opcional)"
    placeholder="Inscrição estadual"
    type="text"
    {...inputProps}
  />
)
