import { Avatar, Checkbox, Group, Select, Text, TextInput, useMantineTheme } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { useMediaQuery } from '@mantine/hooks'
import { IconCreditCardOff } from '@tabler/icons'
import { AxiosResponse } from 'axios'
import cardValidator from 'card-validator'
import React, { useEffect, useState } from 'react'
import { FormattedNumber } from 'react-intl'

import {
  Installments as InstallmentsType,
  PaymentMethodOption as PaymentMethodOptionType
} from '@/types'

interface Props {
  inputProps: any
  cardOptions?: PaymentMethodOptionType[]
  form?: UseFormReturnType<any, any>
  errorMessage?: string
  callback?: (data: AxiosResponse | undefined, status: number | undefined) => void
}

export const NumberField = ({ inputProps, cardOptions, form, errorMessage }: Props) => {
  // States
  const [availableCard, setAvailableCard] = useState<PaymentMethodOptionType | null | undefined>(
    null
  )
  // Actions
  const checkCardNumber = () => {
    const inputCard = cardValidator.number(form?.values?.number)
    return cardOptions?.some(card => card?.paymentMethod?.name === inputCard?.card?.niceType)
  }

  // Effects
  useEffect(() => {
    const isValid = checkCardNumber()
    if (form?.values?.number && !isValid) form?.setFieldError('number', errorMessage)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkCardNumber, cardOptions, form?.values.number])

  return (
    <TextInput
      type="text"
      label="Número do cartão"
      placeholder="Número do cartão"
      autoComplete="cc-number"
      rightSection={
        <Avatar
          src={availableCard?.paymentMethod?.brand?.logo}
          alt={availableCard?.paymentMethod?.brand?.name}
          size={30}
          radius="sm"
          mr="sm">
          <IconCreditCardOff size={18} />
        </Avatar>
      }
      {...inputProps}
      onBlur={() => checkCardNumber()}
      onChange={e => {
        const input = cardValidator.number(e.target.value)
        const inputCard = cardOptions?.find(
          card => card?.paymentMethod?.name === input?.card?.niceType
        )
        setAvailableCard(inputCard)
        form?.setFieldValue('number', e.target.value)
      }}
    />
  )
}

export const NameField = ({ inputProps }: Props) => (
  <TextInput
    type="text"
    label="Nome"
    placeholder="Digite o nome impresso no cartão"
    autoComplete="cc-name"
    {...inputProps}
  />
)

export const ValidThruField = ({ inputProps }: Props) => (
  <TextInput
    type="text"
    label="Data de validade"
    placeholder="Válido até"
    autoComplete="cc-exp"
    maxLength={7}
    {...inputProps}
  />
)

export const CVVField = ({ inputProps }: Props) => (
  <TextInput
    type="text"
    label="CVC"
    placeholder="CVC"
    autoComplete="cc-csc"
    maxLength={4}
    {...inputProps}
  />
)

export const TaxDocumentField = ({ inputProps }: Props) => (
  <TextInput
    label="CPF/CNPJ"
    placeholder="CPF ou CNPJ do titular do cartão"
    type="text"
    {...inputProps}
  />
)

export const StoredCheckbox = ({ inputProps }: Props) => (
  <Checkbox
    label={
      <Text weight={inputProps?.checked ? 'bold' : 'normal'}>
        Salvar este cartão para utilização em compras futuras
      </Text>
    }
    {...inputProps}
    sx={{ label: { '&:hover': { cursor: 'pointer' } } }}
  />
)

interface InstallmentsProps {
  selected: any
  installments: InstallmentsType[]
  inputProps?: any
  onChange?: (installments: any) => void
}

export const InstallmentsSelectField = ({
  selected,
  installments,
  inputProps,
  onChange
}: InstallmentsProps) => {
  // Hooks
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)

  // Constants
  interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    installments: number | string
    installmentAmount: number | string
    hasInterest: boolean
    total: number | string
  }
  // eslint-disable-next-line react/display-name
  const SelectItem = React.forwardRef<HTMLDivElement, ItemProps>(
    ({ installments, installmentAmount, hasInterest, total, ...others }: ItemProps, ref: any) => (
      <div ref={ref} {...others}>
        <Group position="apart" noWrap>
          <Text color={installments === selected ? 'white' : 'dark'} sx={{ whiteSpace: 'nowrap' }}>
            {installments}x{' '}
            <FormattedNumber style="currency" currency="BRL" value={Number(installmentAmount)} />
            <Text span italic color={installments === selected ? 'white' : 'dimmed'}>
              {hasInterest ? ' com juros' : ' sem juros'}
            </Text>
          </Text>
          <Text color={installments === selected ? 'white' : 'dark'} sx={{ whiteSpace: 'nowrap' }}>
            <FormattedNumber style="currency" currency="BRL" value={Number(total)} />{' '}
            <Text span italic color={installments === selected ? 'white' : 'dimmed'}>
              total
            </Text>
          </Text>
        </Group>
      </div>
    )
  )

  const options: any[] = installments?.map(installment => ({
    installments: installment?.installments,
    installmentAmount: installment?.installmentAmount,
    hasInterest: installment?.hasInterest,
    total: installment?.total,
    value: installment?.installments,
    label: `${installment?.installments}x ${installment?.hasInterest ? 'com juros' : 'sem juros'}`
  }))

  return (
    <Select
      label="Em quantas parcelas gostaria de pagar?"
      placeholder="Selecione as parcelas"
      size="sm"
      maxDropdownHeight={400}
      itemComponent={SelectItem}
      value={selected}
      data={options}
      onClick={e => e.stopPropagation()}
      {...(inputProps || {})}
      {...(onChange ? { onChange } : {})}
      sx={{ width: isXs ? '100%' : 'auto', minWidth: 300 }}
    />
  )
}
