export const acceptTypes = [
  'application/pdf',
  'text/csv',
  'application/rtf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'video/x-msvideo',
  'video/mp4',
  'video/mpeg',
  'video/ogg',
  'video/webm',
  'application/x-7z-compressed',
  'application/vnd.rar',
  'application/gzip',
  'application/zip',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'image/gif',
  'image/png',
  'image/jpeg',
  'image/tiff',
  'image/svg+xml',
  'image/webp',
  'image/bmp'
]
