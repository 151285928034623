import { TextInput } from '@mantine/core'
import { AxiosResponse } from 'axios'

interface Props {
  inputProps: any
  callback?: (data: AxiosResponse | undefined, status: number | undefined) => void
}

export const FirstNameField = ({ inputProps }: Props) => (
  <TextInput {...inputProps} placeholder="Nome" type="text" />
)

export const LastNameField = ({ inputProps }: Props) => (
  <TextInput {...inputProps} placeholder="Sobrenome" type="text" />
)

export const EmailField = ({ inputProps }: Props) => (
  <TextInput {...inputProps} placeholder="E-mail" type="email" />
)

export const PasswordField = ({ inputProps }: Props) => (
  <TextInput {...inputProps} placeholder="Senha" type="password" />
)

export const ConfirmPasswordField = ({ inputProps }: Props) => (
  <TextInput {...inputProps} placeholder="Confirmar Senha" type="password" />
)
