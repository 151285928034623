import { Button, Grid, Group, useMantineTheme } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useMediaQuery } from '@mantine/hooks'
import { AxiosError } from 'axios'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { KeyedMutator } from 'swr'

import { api, getUrlString, notificationHandler, Yup } from '@/lib/utils'
import { MaskedTaxDocumentCNPJ } from '@/lib/utils/masks'
import { useRouterLoading } from '@/providers/RouterLoadingProvider'
import { Organization as OrganizationType } from '@/types'

import * as OrganizationFormFields from './Fields'

interface Props {
  values?: OrganizationType | null
  callback?: KeyedMutator<any> | null
}

interface FormValues {
  officialName: string
  fantasyName: string
  companyTaxDocument: string
  companyStateTaxDocument: string
}

export default function OrganizationForm({ values, callback }: Props) {
  // Hooks
  const router = useRouter()
  const { site: siteSlug, basketUid, callbackUrl: urlQuery } = router.query || {}
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)
  const { isLoading: isLoadingGlobal } = useRouterLoading()

  // States
  const [isSubmitting, setIsSubmitting] = useState(false)

  // Constants
  const companyTaxDocumentRegex = /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})/
  const callbackUrl = getUrlString(urlQuery)
  const redirectHref = callbackUrl
    ? `/checkout/${basketUid}/organizations?callbackUrl=${callbackUrl}`
    : `/checkout/${basketUid}/organizations`

  // Initial values
  const organization = {
    uid: values?.uid || '',
    officialName: values?.officialName || '',
    fantasyName: values?.fantasyName || '',
    companyTaxDocument:
      values?.documents?.find((doc: any) => doc.type === 'COMPANY_TAX_DOCUMENT')?.document || '',
    companyStateTaxDocument:
      values?.documents?.find((doc: any) => doc.type === 'COMPANY_STATE_TAX_DOCUMENT')?.document ||
      ''
  }

  // Validation schema
  const schema = Yup.object().shape({
    officialName: Yup.string().min(2).required(),
    fantasyName: Yup.string().required(),
    companyTaxDocument: Yup.string()
      .matches(companyTaxDocumentRegex, 'Digite um CNPJ válido')
      .required(),
    companyStateTaxDocument: Yup.string().notRequired()
  })

  // Mantine form
  const form = useForm<FormValues>({
    validate: yupResolver(schema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
    clearInputErrorOnChange: false,
    initialValues: { ...organization }
  })

  // Actions
  const errorHandler = (error: AxiosError) => {
    const { response } = error
    const errorData = response?.data || ({} as any)
    const errorKey = Object.keys(errorData)[0]
    return notificationHandler({
      variant: 'error',
      message: errorData[errorKey] || 'Houve um erro durante o registro dos dados da organização'
    })
  }

  const setCheckoutOrganization = (uid: string) => {
    return api
      .patch(`/${siteSlug}/checkout/${basketUid}/`, { organization: uid })
      .then(() => router.push(redirectHref))
      .catch(errorHandler)
      .finally(() => setIsSubmitting(false))
  }

  const handleSubmit = (newValues: FormValues) => {
    setIsSubmitting(true)

    const newOrganization: OrganizationType = {
      officialName: newValues.officialName,
      fantasyName: newValues.fantasyName,
      documents: [
        { type: 'COMPANY_TAX_DOCUMENT', document: newValues.companyTaxDocument },
        ...(newValues.companyStateTaxDocument
          ? [{ type: 'COMPANY_STATE_TAX_DOCUMENT', document: newValues.companyStateTaxDocument }]
          : [])
      ]
    }

    // Update or create
    if (values?.uid) {
      api
        .patch(`/${siteSlug}/organizations/${values.uid}/`, newOrganization)
        .then(response => {
          callback?.()
          notificationHandler({ variant: 'success', message: 'Organização atualizada com sucesso' })
          return response?.data?.uid
        })
        .then(setCheckoutOrganization)
        .catch(errorHandler)
        .finally(() => setIsSubmitting(false))
    } else {
      api
        .post(`/${siteSlug}/organizations/`, newOrganization)
        .then(response => {
          notificationHandler({ variant: 'success', message: 'Organização criada com sucesso' })
          return response?.data?.uid
        })
        .then(setCheckoutOrganization)
        .catch(errorHandler)
        .finally(() => setIsSubmitting(false))
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Grid>
        <Grid.Col span={12}>
          <OrganizationFormFields.OfficialName
            inputProps={{ ...form.getInputProps('officialName'), disabled: isSubmitting }}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <OrganizationFormFields.FantasyName
            inputProps={{ ...form.getInputProps('fantasyName'), disabled: isSubmitting }}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <OrganizationFormFields.CompanyTaxDocument
            inputProps={{
              ...form.getInputProps('companyTaxDocument'),
              component: MaskedTaxDocumentCNPJ,
              disabled: isSubmitting
            }}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <OrganizationFormFields.CompanyStateTaxDocument
            inputProps={{
              ...form.getInputProps('companyStateTaxDocument'),
              disabled: isSubmitting
            }}
          />
        </Grid.Col>
        <Grid.Col span={12} mt="xl">
          <Group position="apart" spacing="md">
            <Button
              size={isXs ? 'sm' : 'md'}
              fullWidth={!!isXs}
              variant="outline"
              disabled={isSubmitting || isLoadingGlobal}
              component={Link}
              href={redirectHref}>
              Voltar
            </Button>
            <Button
              type="submit"
              size={isXs ? 'sm' : 'md'}
              fullWidth={!!isXs}
              disabled={!form.isValid() || isLoadingGlobal}
              loading={isSubmitting}>
              {values?.uid ? 'Salvar' : 'Adicionar'}
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </form>
  )
}
