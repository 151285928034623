import { Button, FileInput, TextInput } from '@mantine/core'
import { IconUpload } from '@tabler/icons'
import { AxiosResponse } from 'axios'

const acceptTypes = ['image/gif', 'image/png', 'image/jpeg', 'image/webp', 'image/bmp']

interface Props {
  inputProps: any
  callback?: (data: AxiosResponse | undefined, status: number | undefined) => void
}

export const FirstNameField = ({ inputProps }: Props) => (
  <TextInput {...inputProps} label="Nome*" placeholder="Nome" type="text" />
)

export const LastNameField = ({ inputProps }: Props) => (
  <TextInput {...inputProps} label="Sobrenome*" placeholder="Sobrenome" type="text" />
)

export const PasswordField = ({ inputProps }: Props) => (
  <TextInput {...inputProps} label="Senha" placeholder="Senha" type="password" />
)

export const ConfirmPasswordField = ({ inputProps }: Props) => (
  <TextInput
    {...inputProps}
    label="Confirmar Senha"
    placeholder="Confirmar Senha"
    type="password"
  />
)

export const OldPasswordField = ({ inputProps }: Props) => (
  <TextInput {...inputProps} label="Senha Antiga*" placeholder="Senha Antiga" type="password" />
)

export const PictureField = ({ inputProps }: Props) => (
  <Button variant="outline" leftIcon={<IconUpload />} component="label" fullWidth>
    Alterar foto
    <FileInput
      {...inputProps}
      placeholder="Foto"
      label="Foto"
      multiple
      accept={acceptTypes.join(',')}
      sx={{ display: 'none' }}
    />
  </Button>
)
