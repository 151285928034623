import { api } from '@/lib/utils'
import { User as UserType } from '@/types'

export const endpoints = {
  signup: (site: string) => `/${site}/accounts/signup/`
}

export const signup = ({
  site,
  channel,
  userData
}: {
  site: string
  channel?: string
  userData: UserType
}) => {
  const config = {
    method: 'post',
    url: endpoints.signup(site),
    data: {
      ...userData,
      ...(channel ? { channel: decodeURIComponent(channel) } : {})
    }
  }
  return api(config)
}
