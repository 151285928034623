import { Button, Group, PasswordInput, Stack, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { getUrlString, notificationHandler } from '@/lib/utils'
import { useAuth } from '@/providers/AuthProvider'

export default function ResetPasswordForm() {
  // Hooks
  const router = useRouter()
  const { resetPassword } = useAuth() || {}
  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validate: {
      password: val => (val.length < 8 ? 'A senha deve conter no mínimo 8 caracteres' : null),
      confirmPassword: (val, values) =>
        val !== values.password ? 'As senhas não correspondem' : null
    },
    validateInputOnChange: true
  })

  // Constants
  const { uidb64, hash } = router.query
  const uidb64Slug = getUrlString(uidb64)
  const hashSlug = getUrlString(hash)
  const isDisabled = !form.isTouched() || !form.isValid()

  // States
  const [isLoading, setIsLoading] = useState(false)

  // Actions
  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      await resetPassword?.(form.values.password, uidb64Slug, hashSlug)
      notificationHandler({ variant: 'success', message: 'Senha atualizada com sucesso!' })
      router.push('/accounts/login')
    } catch (error) {
      const { response } = error as AxiosError
      notificationHandler({
        variant: 'error',
        message:
          Object.values(response?.data || {}).join('<br />') ||
          'Ocorreu um erro ao resetar a senha. Tente novamente mais tarde.'
      })
    } finally {
      form.resetDirty()
      form.resetTouched()
      form.reset()
      setIsLoading(false)
    }
  }

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <Title size="sm">Resetar senha</Title>
          <PasswordInput
            required
            placeholder="Senha"
            disabled={isLoading}
            {...form.getInputProps('password')}
            onBlur={form.validate}
          />
          <PasswordInput
            required
            placeholder="Confirmar senha"
            disabled={isLoading}
            {...form.getInputProps('confirmPassword')}
            onBlur={form.validate}
          />
        </Stack>
        <Group position="right" mt="xl">
          <Button type="submit" disabled={isDisabled} loading={isLoading}>
            Resetar senha
          </Button>
        </Group>
      </form>
    </>
  )
}
